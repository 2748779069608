import React from "react"

import SEO from "../components/SEO"
import Container from 'react-bootstrap/Container'

const NotFoundPage = () => {
  return (
    <Container>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  )
}

export default NotFoundPage
